import React, {useState} from 'react'
import firebase from '../firebase'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

const ContainerStyled =styled(Container)`
    
    background:#e6e6e6;
    margin-top:1em;
    margin-bottom:1em;
    padding-top:1em;
    padding-bottom:1em;

    .form{
        display: grid;
        padding:1.2em;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
        column-gap: 10px;
        row-gap: 15px;
        border: 1px solid #ced4da

    }
    .button{
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start:2;
        grid-column-end:3;
    }
    .row{
        margin:0px;
    }

    .title{
        color:white;
        background-color:#591415;
        display:flex;
        justify-content:center;
        align-items:center;
        padding:1em 1em;
        
    }
    .description{
        background-color:#e6e6e6;
        font-size:1.2em;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .paragraph{
        margin:0;
    }

    @media (max-width: 768px) { 
        .form{
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto auto;
        }
        .button{
            grid-row-start: 5;
            grid-row-end: 6;
            grid-column-start:1;
            grid-column-end:2;
        }
    }

`

const FormClients=()=> {
    const [nombre,setName]=useState('');
    const [correo,setEmail]=useState('');
    const [numero,setPhoneNumber]=useState('');

    function onSubmit(e){
        e.preventDefault();
        const event = new Date();
        firebase.firestore().collection('clientes').doc(event.toString())
            .set({
                nombre,
                correo,
                numero,
                fecha: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(()=>{
                setName('')
                setEmail('')
                setPhoneNumber('')
            })

    };

    return (
        <ScrollAnimation animateIn="fadeInUp" duration={1.5} animateOnce={true}>
        <ContainerStyled>
            <Row className="row">
                <Col className="title" lg={4}><h2>Contacto</h2></Col>
                <Col className="description" lg={8}>
                    <p className="paragraph">Déjanos tus datos y en breve nuestro equipo se podrá en contacto</p>
                </Col>
            </Row>
            <Form onSubmit={onSubmit} className="form">
                <Form.Group controlId="formBasicName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" placeholder="Nombre" value={nombre} onChange={e => setName(e.currentTarget.value)}/>
                </Form.Group> 
            
                <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Email" value={correo} onChange={e => setEmail(e.currentTarget.value)}/>
                <Form.Text className="text-muted">
                    Nunca te enviaremos spam, ni compartiremos tu email.
                </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicNumber">
                <Form.Label>Número telefónico</Form.Label>
                <Form.Control type="text" placeholder="Teléfono" value={numero} onChange={e => setPhoneNumber(e.currentTarget.value)} />
                </Form.Group>

                <Button variant="primary" type="submit" className="button btn-success">
                Enviar
                </Button>
            </Form>
      </ContainerStyled>
      </ScrollAnimation>
    )
}

export default FormClients
