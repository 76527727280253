import React from 'react';
import HeaderPresentation from '../components/HeaderPresentation';
import Cow from '../components/Cow';
import Pig from '../components/Pig';
import Mutton from '../components/Mutton';
import Poultry from '../components/Poultry';
import {Route} from 'react-router-dom'
import MeatNav from '../components/MeatNav'
import styled from 'styled-components';
import Container from 'react-bootstrap/Container'
import wallpaper from '../assets/images/meat13.jpg'

const ContainerStyled = styled(Container)`
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${wallpaper});
    background-position: center;
    background-attachment: fixed;
    margin:0px;
    padding:1em 1em;
`


function Products({ match }) {
 
    return (
            <>
            <HeaderPresentation title="Nuestras Carnes" description="Los Mejores y más Sabrosos Cortes"/>    
            <ContainerStyled fluid>
                <MeatNav/>
                <Route exact path={`${match.path}`} component={Cow}/>
                <Route path={`${match.path}/res`} component={Cow}/>
                <Route path={`${match.path}/cerdo`} component={Pig}/>
                <Route path={`${match.path}/cordero`} component={Mutton}/>
                <Route path={`${match.path}/ave-corral`} component={Poultry}/>
            </ContainerStyled> 
            </>
    )
}

export default Products
