import React from 'react';
import Presentation from '../components/Presentation';
import HeaderPresentation from '../components/HeaderPresentation';
import Info from '../components/Info';
import Container from 'react-bootstrap/Container'
import styled from 'styled-components'
import wallpaper from '../assets/images/meat13.jpg'

const ContainerStyled= styled(Container)`
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${wallpaper});
    background-position: center;
    background-attachment: fixed;
    margin:0px;
    padding:1em 1em;
`

function Company() {
    return (
        <>
        <HeaderPresentation title="Nuestra Empresa" description="Conócenos"/>
        <ContainerStyled fluid>
            <Presentation/>
            <Info/>
        </ContainerStyled>
        </>
    )
}

export default Company
