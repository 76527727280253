import React from 'react'
import HeaderPresentation from '../components/HeaderPresentation';
import Map from '../components/Map';
import Info from '../components/Info';
import FormClients from '../components/FormClients';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import wallpaper from '../assets/images/meat13.jpg'

const ContainerImage= styled(Container)`
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${wallpaper});
    background-position: center;
    background-attachment: fixed;
    margin:0px;
    padding:1em 1em;
`
    


function Contact() {
    return (
        <>
            <HeaderPresentation title="Contáctenos" description="Estamos para Servirlos"/>
            <ContainerImage fluid>
                <Map></Map>
                <FormClients></FormClients>
                <Info></Info>
            </ContainerImage>
        </>
    )
}

export default Contact
