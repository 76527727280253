import React from 'react'
import Figure from 'react-bootstrap/Figure'
import Button from 'react-bootstrap/Button'
import {Link, useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'

const Styles=styled(Container)`
    background-color:#e6e6e6;
    border-radius: 10px;
    display:flex;
    flex-direction:column;

    align-items:center;
    padding:10px 10px 10px 10px;

    .link{
        text-decoration:none;
        color: white;
    }
    .btn{
        background-color:#3d080a;
        border-color: #3d080a;
    }
    .btn:hover{
        background-color:#591415;
    }
    .h3{
        text-align:center;
    }
    .icon-container{
        display:flex;
        justify-content:space-evenly;
        align-items:center;
    }
    .img{
        width:2.5em;
    }
    
`

function MeatContainer(props) {
    const { title,caption,image,link,button,icon1,icon2,icon3,icon4} = props;
    let { url } = useRouteMatch();

    if(`${url}`==="/productos"|| `${url}`==="/productos/res"|| `${url}`==="/productos/cordero"|| `${url}`==="/productos/cerdo" || `${url}`==="/productos/ave-corral"){
        return(
            <Styles fluid>
                <Figure>    
                    <Figure.Image
                        alt="image"
                        src={require(`../assets/images/${image.toLowerCase()}.jpg`)}
                        loading="lazy"
                        title={title}
                    />
                    <h3 className="h3">{title}</h3>
                    <Row className="icon-container">
                        {
                            icon1 ? <Image src={require(`../assets/icons/${icon1.toLowerCase()}.png`)} className="img" fluid loading="lazy"
                            title={icon1} alt={icon1}/>
                                :null
                        }
                        {
                            icon2 ? <Image src={require(`../assets/icons/${icon2.toLowerCase()}.png`)} className="img"  fluid loading="lazy"
                            title={icon2} alt={icon2}/>
                                :null
                        }
                        {
                            icon3 ? <Image src={require(`../assets/icons/${icon3.toLowerCase()}.png`)} className="img"  fluid loading="lazy"
                            title={icon3} alt={icon3}/>
                                :null
                        }
                        {
                            icon4 ? <Image src={require(`../assets/icons/${icon4.toLowerCase()}.png`)} className="img"  fluid loading="lazy"
                            title={icon4} alt={icon4}/>
                                :null
                        }
                        
                    </Row>
                </Figure>
                <Link className="link" to={link}><Button>{button}</Button></Link>
            </Styles>
        )
    }
    

    return (
        <Styles fluid>
        <Figure>    
            {image? <Figure.Image
                alt="image"
                src={require(`../assets/images/${image.toLowerCase()}.jpg`)}
                loading="lazy"
                title={title}
            />:null }
            <h3 className="h3">{title}</h3>
            <Figure.Caption>
            {caption}
            </Figure.Caption>
        </Figure>
        <Link className="link" to={link}><Button>{button}</Button></Link>
        </Styles>
    )
}

export default MeatContainer
