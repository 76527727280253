import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'

const ContainerStyled = styled(Container)`
    height:12em;
    display:flex;
    justify-content: center;
    align-items:center;
    font-size:4em;
    text-align:center;
`


function NotFound() {
    return (
        <ContainerStyled>
            <div>Error 404<br/>
            Página no encontrada
            </div>
        </ContainerStyled>
    )
}

export default NotFound
