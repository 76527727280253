import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import mapImage from '../assets/images/map.JPG';
import Button from 'react-bootstrap/Button';
import ScrollAnimation from 'react-animate-on-scroll';

const ContainerStyled=styled(Container)`

    
    background-color:#e6e6e6;
    padding-top:1em;
    padding-bottom:1em;
    margin-bottom:1em;
    .row{
        margin:0px;
    }

    .title{
        color:white;
        background-color:#591415;
        display:flex;
        justify-content:center;
        align-items:center;
        
    }
    .description{
        text-align:center;
        background-color:#e6e6e6;
        font-size:1.2em;
        
    }
    .district{
        font-weight:bold;
        margin-top:10px;
    }
    .buttonWrapper{
        display:flex;
        justify-content:center;
    }
    .button{
        margin-top:1em;
        width:25em;
    }

    .map{
        background-image: url(${mapImage});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    @media (max-width: 576px) { 
        .map{ height:100vw;}
        .title{ padding:1em 1em;}
    }

    @media (min-width: 576px) { 
       .map{ height:70vw;}
       .title{ padding:1em 1em;}
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) { 
       .map{ height:50vw;}
       .title{ padding:1em 1em;}
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) { 
        .map{height:30vw;}
        }

`


function Map() {
    return (
        <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
            <ContainerStyled>
                <Row className="row">
                    <Col className="title" lg={4}><h2>Ubícanos</h2></Col>
                    <Col className="description" lg={8}>
                        <p className="district">La Victoria</p>
                        <p className="avenue">Av. México Cuadra 20</p>
                        <p className="street">Luis de Velasco 2086</p>
                    </Col>
                </Row>
                <Container className= 'map' fluid/>
                <div className="buttonWrapper"><Button className="button btn-success" href="https://www.google.com/maps/place/Velasco+2086,+Cercado+de+Lima+15019/@-12.0684257,-77.0092883,17z/data=!3m1!4b1!4m5!3m4!1s0x9105c626da3ad275:0x5a19a57eff0afb56!8m2!3d-12.0684257!4d-77.0070996" target="_blank"> Abrir Mapa</Button></div>
            </ContainerStyled>
        </ScrollAnimation>
    )
}

export default Map;
