import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDsenyyRcWXmr2Pc5SRUBeAkt7xg42BeKI",
    authDomain: "elbife-ab38d.firebaseapp.com",
    databaseURL: "https://elbife-ab38d.firebaseio.com",
    projectId: "elbife-ab38d",
    storageBucket: "elbife-ab38d.appspot.com",
    messagingSenderId: "283787351265",
    appId: "1:283787351265:web:9d9306c275bc1e201013a0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase