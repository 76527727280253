import React from 'react'
import Container from 'react-bootstrap/Container'
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ScrollAnimation from 'react-animate-on-scroll'
import whatsapp from '../assets/icons/icons8-whatsapp.svg'
import telephone from '../assets/icons/icons8-telefono-de-oficina-100.png'
import smartphone from '../assets/icons/phone3.png'
import facebook from '../assets/icons/icons8-facebook-96.png'

const ContainerStyled = styled(Container)`


    .card{
        background-color:rgba(0,0,0,0.6);
        color:white;
        margin:10px 10px;
    }
    .line{
        color: white;
        background-color: white;
        height: 2px;
        border-color : white;
    }
    .img{
        width:1.5em;
    }
    .phoneNumbers{
        display:flex;
        flex-direction:column;
    }
    .numberContainer{
        display:flex;
        align-items:center;
        margin-bottom:0.5em;
        justify-content:left;
    }
    .p{
        margin: 0px 0px 0px 1em;
    }
    .facebook{
        width:3em;
    }
`

function Info() {
    return (
        <ContainerStyled fluid>
            <Container>
                <Row>
                    <Col xs={12} md={4}>
                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                        <Card>
                            <Card.Body >
                                <Card.Title>Carnicerias El Bife</Card.Title>
                                <Card.Text>
                                Nuestra continua misión es dar a cada uno de nuestros clientes, 
                                servicios y productos de calidad lo cual se traduce en beneficios 
                                de tranquilidad y confianza.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} md={4}>
                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Contacto</Card.Title>
                                <Card.Text className="phoneNumbers">
                                    <div className="numberContainer">
                                        <img src={whatsapp} className="img" alt="whatsapp icon" loading="lazy"
                                        title="whatsapp"/> 
                                        <p className="p">  981 159 394</p> 
                                    </div>
                                    <div className="numberContainer">
                                        <img src={smartphone} className="img" alt="smartphone icon" loading="lazy"
                                        title="smartphone"/> 
                                        <p className="p">  956 030 264 / 955 406 572</p> 
                                    </div>
                                    <div className="numberContainer">
                                        <img src={telephone} className="img" alt="phone number icon" loading="lazy"
                                        title="telephone number"/> 
                                        <p className="p"> (01) 464 1598</p> 
                                    </div>
                                </Card.Text>
                                <hr className="line"/>
                                <Card.Title>Horario</Card.Title>
                                <Card.Text>
                                    Lunes a Sábado de 6am a 2pm
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        </ScrollAnimation>
                    </Col>
                    <Col xs={12} md={4}>
                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Recibe nuestras ofertas</Card.Title>
                                <Card.Text>
                                Escribenos a <strong>ventas@carniceriaelbife.com</strong>
                                </Card.Text>
                                <hr className="line"/>
                                <Card.Title>Síguenos en nuestras redes</Card.Title>
                                <Card.Text>
                                    <a href="https://www.facebook.com/El-Bife-108932120804822" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook logo" className="facebook" loading="lazy" title="Facebook"></img></a>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                      </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </ContainerStyled>
    )
}

export default Info
