import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'
import meat18 from '../assets/images/meat18.jpg'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import iconArrowDown from '../assets/icons/arrow.png'
import ScrollAnimation from 'react-animate-on-scroll'

const ContainerStyled = styled(Container)`
    margin-top:15px;
    margin-bottom:15px;
    .card{
        border:none;
    }
    
    .row{
        margin-bottom:15px;
    }
    .card-header{
        text-decoration: none;
        color:white;
        font-weight:bold;
        background-color:#591415;
        
    }
    .title{
        font-size:1.5em;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    .description{
        font-size:1.2em;
        background-color:#e6e6e6;
        text-align: justify;
    }
    .icon{
        width: 0.8em;
        height:0.8em;
    }

    @media (max-width: 576px) { 
        .title{
            font-size:1.2em;
        }
        .description{
            font-size:1em;
        }
    }

    @media (min-width: 576px) { 
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) { 
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) { 
        
        }
   `

function Presentation() {
    return (
        <ContainerStyled>
            <Row>
                <Col xs={12} md={6}>
                    <ScrollAnimation animateIn="fadeInLeft" duration={2} animateOnce={true}>
                        <Card>
                            <Card.Body className="description">
                            <p>
                            <strong>El bife S.A.C</strong> está compuesto por profesionales en el rubro de alimentos frescos tales como: <strong>Res, Pollos, Caprinos, Porcinos</strong> y otros de consumo masivo. Llevamos trabajando en este rubro <strong>más de 20 años</strong> lo cual respaldan nuestra experiencia y calidad.
                            </p>
                            <p>La propuesta abarca la recepción de pedidos diarios y la <strong>distribución</strong> de los mismos a las <strong>horas</strong> pactadas con nuestros clientes, manteniendo siempre la <strong>puntualidad</strong> en nuestras entregas ya que este es un factor muy importante para nosotros.
                            </p>
                            <p>
                            Adicionalmente contamos con <strong>movilidades propias</strong> adecuadas para el rubro como camión frigorífico el mismo que nos permite trasportar los alimentos con estándares de <strong>alta calidad</strong>.
                            Mantenemos constante <strong>comunicación</strong> con nuestros clientes a fin de brindarles un <strong>mejor servicio</strong> cada día.
                            </p>
                            <p>Esperamos que nuestra propuesta supla <strong>sus necesidades</strong>, si tiene alguna duda o comentario al respecto <strong>no dude en contactarnos</strong>.
                            </p>
                            </Card.Body>
                        </Card>
                    </ScrollAnimation>
                </Col>
                <Col xs={12} md={6}>
                    <ScrollAnimation animateIn="fadeInRight" duration={2} animateOnce={true}>
                        <Image src={meat18} rounded fluid loading="lazy" title="Nuestra Empresa EL BIFE SAC" alt="Nuestra Empresa EL BIFE SAC"/>
                    </ScrollAnimation>
                </Col>
            </Row>
            <Row>
                <Col>
                <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                    <Accordion defaultActiveKey="0">
                        <Card className="card">
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="title border-bottom">
                            <h2>Misión</h2><img className="icon" src={iconArrowDown} alt="arrow icon" loading="lazy" title="icono flecha"></img>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body className="description">La misión de nuestra empresa es la creciente creación de valor, logrando un destacado retorno de las inversiones, superior al costo del capital empleado.
                            En nuestros negocios de alimentos  buscamos siempre mejorar la calidad de vida del consumidor y el progreso de nuestra gente.
                            Buscamos el crecimiento rentable con marcas líderes, servicio superior y una excelente distribución a nivel nacional e internacional.
                            Gestionamos nuestras actividades comprometidos con el desarrollo sostenible; con el mejor talento humano; innovación sobresaliente y un comportamiento corporativo ejemplar.
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="title">
                            <h2>Visión</h2> <img className="icon" src={iconArrowDown} alt="arrow icon" loading="lazy" title="icono flecha"></img>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body className="description">Juntos lograremos triplicar nuestro negocio de alimentos, proporcionando calidad de vida al consumidor con productos que satisfagan 
                                sus aspiraciones de bienestar, nutrición y placer.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </ScrollAnimation>
                </Col>
            </Row>
        </ContainerStyled>
    )
}

export default Presentation
