import React, { useState, useEffect }  from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import {Link, useRouteMatch, useLocation} from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import cowlogo from '../assets/icons/icons8-cow-breed-60.png'
import piglogo from '../assets/icons/icons8-cerdo-48.png'
import sheeplogo from '../assets/icons/icons8-oveja-50.png'
import chickenlogo from '../assets/icons/icons8-pollo-50.png'


const StyledContainer=styled(Container)`
    display:flex;
    justify-content:center;
    padding: 1em 1em;

    .wrapper{
        display:grid;
        border: 2px solid #e6e6e6;
    }
    .link{
        color:black;
        text-decoration:none;
        display:flex;
        flex-direction:column;
        padding:0.2em;
    }
    .selected{
        background-color:#591415;
        color:white;
    }
    .no-selected{
        background-color:#e6e6e6;
        color:black;
    }

    .title{
        padding:10px;
        text-align:center;
        font-size:1.3em;
    }
    .image-wrapper{
        display:flex;
        justify-content:center;
    }

    // Small devices (landscape phones, 576px and up)
    @media (max-width: 576px) { 
        .wrapper{
            grid-template-columns:1fr 1fr;
        }
    }

    @media (min-width: 576px) { 
        .wrapper{
            grid-template-columns:1fr 1fr;
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) { 
        .wrapper{
            grid-template-columns:1fr 1fr 1fr 1fr;
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) { 
        .wrapper{
            grid-template-columns:1fr 1fr 1fr 1fr;
        }
     }

`


function MeatNav() {
    
    let { url } = useRouteMatch();
    let location = useLocation();

    const [classCow, setclassCow] = useState('no-selected');
    const [classPig, setclassPig] = useState('no-selected');
    const [classSheep, setclassSheep] = useState('no-selected');
    const [classChicken, setclassChicken] = useState('no-selected');

    const handleMeatSelection = () => {
        if(`${location.pathname}`==='/productos/res' || `${location.pathname}`==='/productos' ){
            setclassCow('selected');
            setclassPig('no-selected');
            setclassSheep('no-selected');
            setclassChicken('no-selected');
        }else if(`${location.pathname}`==='/productos/cerdo') {
            setclassCow('no-selected');
            setclassPig('selected');
            setclassSheep('no-selected');
            setclassChicken('no-selected');
        }else if(`${location.pathname}`==='/productos/cordero') {
            setclassCow('no-selected');
            setclassPig('no-selected');
            setclassSheep('selected');
            setclassChicken('no-selected');
        }else if(`${location.pathname}`==='/productos/ave-corral') {
            setclassCow('no-selected');
            setclassPig('no-selected');
            setclassSheep('no-selected');
            setclassChicken('selected');
        }
    }

    useEffect(()=>{
        handleMeatSelection();  
    })

    return (
        <StyledContainer fluid>
            <div className="wrapper">
                <Link to={`${url}/res`} className={`${classCow} link`}>
                    <h2 className="title">Cortes de Res</h2>
                    <div className="image-wrapper"><Image src={cowlogo} loading="lazy" title="Cortes de Res" alt="cortes de res"></Image></div>
                </Link>
                <Link to={`${url}/cerdo`} className={`${classPig} link`}>
                    <h2 className="title">Cortes de Cerdo</h2>
                    <div className="image-wrapper"><Image src={piglogo} loading="lazy" title="Cortes de Cerdo" alt="cortes de cerdo"></Image></div>
                </Link>
                <Link to={`${url}/cordero`} className={`${classSheep} link`}>
                    <h2 className="title">Cortes de Cordero</h2>
                    <div className="image-wrapper"><Image src={sheeplogo} loading="lazy" title="Cortes de Cordero" alt="cortes de cordero"></Image></div>
                </Link>
                <Link to={`${url}/ave-corral`} className={`${classChicken} link`}>
                    <h2 className="title">Cortes de Pollo</h2>
                    <div className="image-wrapper"><Image src={chickenlogo} loading="lazy" title="Cortes de Pollo" alt="cortes de pollo"></Image></div>
                </Link>
            </div>
        </StyledContainer>
    )
}

export default MeatNav
